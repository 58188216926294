.SignInError {
  padding: 80px 0;
  text-align: center;
}

.SignInError awsui-icon {
  margin-bottom: 20px !important;
}

.SignInError awsui-button {
  margin-top: 50px !important;
}

.Home .home__header {
  background-color: #232f3e;
  padding: 4rem 0 3rem 0;
  width: 100%;
}

.Home .home__category,
.Home .home__header-title,
.Home .home__header-title p {
  font-weight: lighter;
}

.Home .home__category,
.Home .home__header-title {
  color: #fff;
}

.Home .home__header-title p {
  color: #d5dbdb;
}

@media (min-width: 992px) {
  .Home .home__sidebar {
    margin-top: -6rem;
  }
}

.Home .table-row {
  border-bottom: 1px solid #eaeded;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Home .table-row:first-child {
  padding-top: 0;
}

.Home .table-row:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.Home .landing {
  padding: 80px 0;
  text-align: center;
}

.Home .landing h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .landing div {
  padding-top: 20px;
}

.Home .landing div a {
  min-width: 320px;
}
